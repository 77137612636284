import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    FormField,
    Input,
    ColumnLayout,
    Container,
    Header,
    Select,
    SpaceBetween,
    DatePicker,
    Modal,
    Box,
    TextContent,
} from '@amzn/awsui-components-react-v3';

import { useActivityGroup, useActivityDetailsFormOptions } from '../hooks';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';
import {
    DATE_PICKER_DISPLAY_FORMAT,
    getSelectedOptionValue,
    valuesToOptions,
    unixTimestampToDatePickerValue,
} from '../../../../../common/utils/cloudscapeHelpers';
import { ActivityGroupMode } from '../../../../interfaces/activityGroup';

const ActivityGroupDetails = (props: {
    mode: ActivityGroupMode;
    redirectToEdit: () => void;
}) => {
    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const [formOptions, formOptionsLoading, coursesLoading] =
        useActivityDetailsFormOptions();
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [statusModalMessage, setStatusModalMessage] = useState('');

    const { activityStatuses, courseOptions, programs, timezones } =
        formOptions;
    const existingGroupMode = ['EDIT', 'VIEW'].includes(props.mode);
    const isReadOnlyMode = props.mode === 'VIEW';

    const courseSelectOptions = courseOptions.map((course) => ({
        value: JSON.stringify(course),
        label: course.courseName,
    }));

    const selectedCourse = useMemo(() => {
        return courseOptions.find(
            (course) => course.catalogId === activityGroup.catalog_item_id,
        );
    }, [activityGroup.catalog_item_id, courseOptions]);

    return (
        <Container header={<Header>Activity Group</Header>}>
            <SpaceBetween size="l" direction="vertical">
                <ColumnLayout columns={3}>
                    <FormField label="Activity Group Name">
                        <Input
                            disabled={existingGroupMode}
                            value={activityGroup.name}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityGroup(
                                        {
                                            ...activityGroup,
                                            name: e.detail.value,
                                        },
                                    ),
                                )
                            }
                            placeholder="Enter Activity Group Name"
                            data-testid="activity-group-name-input"
                        ></Input>
                    </FormField>

                    <FormField label="Program">
                        <Select
                            disabled={existingGroupMode}
                            options={valuesToOptions(programs)}
                            selectedOption={getSelectedOptionValue(
                                activityGroup.program_name,
                            )}
                            placeholder="Select Program"
                            empty="User is not assigned to any programs that support activity groups"
                            filteringType="auto"
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityGroup(
                                        {
                                            ...activityGroup,
                                            program_name:
                                                e.detail.selectedOption.value,
                                        },
                                    ),
                                )
                            }
                            data-testid="program-select"
                        ></Select>
                    </FormField>

                    <FormField label="Course">
                        <Select
                            disabled={existingGroupMode}
                            options={
                                formOptionsLoading || coursesLoading
                                    ? []
                                    : courseSelectOptions
                            }
                            selectedOption={
                                formOptionsLoading ||
                                coursesLoading ||
                                !selectedCourse
                                    ? null
                                    : getSelectedOptionValue(
                                          selectedCourse.courseName,
                                      )
                            }
                            statusType={
                                formOptionsLoading || coursesLoading
                                    ? 'loading'
                                    : 'finished'
                            }
                            empty="Selected program has no courses supported by Activity Groups"
                            loadingText="Loading courses for selected program"
                            placeholder={
                                formOptionsLoading || coursesLoading
                                    ? 'Loading courses...'
                                    : 'Select Course'
                            }
                            onChange={(e) => {
                                const selectedCourse = JSON.parse(
                                    e.detail.selectedOption.value,
                                );

                                dispatch(
                                    activityGroupSlice.actions.setCourse({
                                        course_name: selectedCourse.courseName,
                                        catalog_item_versioned_id:
                                            selectedCourse.catalogVersionedId,
                                        catalog_item_id:
                                            selectedCourse.catalogId,
                                    }),
                                );
                            }}
                            filteringType="auto"
                            data-testid="course-select"
                        ></Select>
                    </FormField>

                    <FormField label="Status">
                        <Select
                            options={valuesToOptions(activityStatuses)}
                            selectedOption={{
                                value: activityGroup.status || 'Hold',
                                label: activityGroup.status || 'Hold',
                            }}
                            disabled={!(props.mode === 'EDIT')}
                            placeholder="Select Status"
                            onChange={(e) => {
                                dispatch(
                                    activityGroupSlice.actions.setActivityGroupStatus(
                                        e.detail.selectedOption.value as any,
                                    ),
                                );

                                if (
                                    e.detail.selectedOption.value === 'Active'
                                ) {
                                    setStatusModalMessage(
                                        'Setting the Activity Group status to Active is a one-way change ' +
                                            'that will result in Activities being made available for registration by customers.',
                                    );
                                    setStatusModalOpen(true);
                                } else if (
                                    e.detail.selectedOption.value === 'Canceled'
                                ) {
                                    setStatusModalMessage(
                                        'Setting the Activity Group status to Canceled is a one-way change ' +
                                            'that will result in Activities being made unavailable for registration by customers.',
                                    );
                                    setStatusModalOpen(true);
                                }
                            }}
                            data-testid="status-select"
                        ></Select>
                        <Modal
                            visible={statusModalOpen}
                            onDismiss={() => {
                                setStatusModalOpen(false);
                            }}
                            header="One-Way Status Change Detected"
                            footer={
                                <Box float="right">
                                    <Button
                                        variant="primary"
                                        onClick={() =>
                                            setStatusModalOpen(false)
                                        }
                                    >
                                        Acknowledge
                                    </Button>
                                </Box>
                            }
                        >
                            <TextContent>{statusModalMessage}</TextContent>
                        </Modal>
                    </FormField>
                </ColumnLayout>

                <SpaceBetween size="l" direction="horizontal">
                    <FormField label="Time zone">
                        <Select
                            options={valuesToOptions(timezones)}
                            selectedOption={getSelectedOptionValue(
                                activityGroup.selected_timezone,
                            )}
                            placeholder="Select a Time Zone"
                            disabled={isReadOnlyMode}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setActivityGroup(
                                        {
                                            ...activityGroup,
                                            selected_timezone:
                                                e.detail.selectedOption.value,
                                        },
                                    ),
                                )
                            }
                            filteringType="auto"
                            data-testid="timezone-select"
                        ></Select>
                    </FormField>
                    <FormField label="Start Date">
                        <DatePicker
                            value={unixTimestampToDatePickerValue(
                                activityGroup.start_timestamp,
                                activityGroup.selected_timezone,
                            )}
                            placeholder={DATE_PICKER_DISPLAY_FORMAT}
                            disabled={isReadOnlyMode}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setStartTimeStampFromDatePickerValue(
                                        {
                                            value: e.detail.value,
                                            selectedTimezone:
                                                activityGroup.selected_timezone,
                                        },
                                    ),
                                )
                            }
                            data-testid="start-date-picker"
                        />
                    </FormField>
                    <FormField label="End Date">
                        <DatePicker
                            value={unixTimestampToDatePickerValue(
                                activityGroup.end_timestamp,
                                activityGroup.selected_timezone,
                            )}
                            placeholder={DATE_PICKER_DISPLAY_FORMAT}
                            disabled={isReadOnlyMode}
                            onChange={(e) =>
                                dispatch(
                                    activityGroupSlice.actions.setEndTimeStampFromDatePickerValue(
                                        {
                                            value: e.detail.value,
                                            selectedTimezone:
                                                activityGroup.selected_timezone,
                                        },
                                    ),
                                )
                            }
                            data-testid="end-date-picker"
                        />
                    </FormField>
                </SpaceBetween>

                {isReadOnlyMode && (
                    <Box float="right">
                        <Button
                            variant="primary"
                            onClick={() => props.redirectToEdit()}
                        >
                            Edit
                        </Button>
                    </Box>
                )}
            </SpaceBetween>
        </Container>
    );
};

export default ActivityGroupDetails;
